<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品销售退货订单</el-breadcrumb-item>
      <el-breadcrumb-item>销售退货入库单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form ref="form" label-width="100px" :model="addPurchaseForm">
        <el-row>
          <el-col>
            <el-form-item label="仓库">
              <el-cascader
                  :options="TreeList" v-model="warehouselist"
                  :props="Treeprops" @change="getcheckList" class="width250"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>

        </el-row>
        <el-form-item label-width="0px">
          <el-table :data="addPurchaseForm.goodslist" border stripe  >
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" width="100px"></el-table-column>
            <el-table-column label="商品名称" prop="g_name"></el-table-column>
            <el-table-column label="商品规格"  width="300px">
              <template slot-scope="scope" >
                <div>{{scope.row.ga_name}}</div>
                <el-select v-model="scope.row.gad_id" placeholder="请选择日期" v-if="scope.row.goodsattrdate.length!=0"
                           clearable class="p_purchase_ids ">
                  <el-option
                      v-for="item in scope.row.goodsattrdate"
                      :key="item.gad_id"
                      :label="`${item.gad_start_date}~${item.gad_end_date}`"
                      :value="item.gad_id">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="单位" prop="gu_name" width="50px"></el-table-column>
            <el-table-column label="销售退货数量" prop="sri_number"></el-table-column>
            <el-table-column label="已入库数量" prop="sri_warhouse_number"></el-table-column>
            <el-table-column label="入库数量">
              <template slot-scope="scope">
                <el-input v-model="scope.row.wei_number" @blur="numbermax(scope.row)"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="经办人：" prop="p_purchase_id">
          <el-select v-model="addPurchaseForm.we_agent" placeholder="请选择"
                     clearable class="p_purchase_id width250">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="入库时间：" prop="p_date">
          <el-date-picker v-model="addPurchaseForm.we_date" class="date width250"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="入库备注：" prop="p_remark">
          <el-input v-model="addPurchaseForm.p_remark" class="p_remark width250"></el-input>
        </el-form-item>
        <!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>

    </el-card>


  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,

      //添加表单的form表单
      addPurchaseForm:{
        w_id:'',
        wp_id:'',
        wei_number:0,
        we_type:3,
        we_date:this.$store.state.daydate,
        we_agent:this.$store.state.m_id,
        sr_id:this.$route.query.editid,
      },
      warehouselist:[],
      userlist:[],
      TreeList:[],
      Treeprops:{
        // multiple: true,
        // checkStrictly: true,
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'w_id',
        label: 'w_name',
        children: 'children'},

    }
  },
  created() {
    this.getpurchaseList()
    this.showSetTreeListDialog()
    this.getuserList()
  },
  methods: {
    //获取销售退货订单数据
    async getpurchaseList() {
      const {data: res} = await this.$http.get(`salereturn/find/${this.$route.query.editid}`)
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.addPurchaseForm.goodslist = res.data.goodslist
      // this.addPurchaseForm.we_date=this.$store.state.daydate
      // this.addPurchaseForm.we_agent=this.$store.state.m_id
      this.addPurchaseForm.we_type=3


    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //展示仓库的对话框
    async showSetTreeListDialog() {
      //获取所有权限的数据
      const {data: res} = await this.$http.get('warehouse/getTreeList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.TreeList = res.data
    },
    getcheckList(){
      this.addPurchaseForm.w_id=this.warehouselist[0]
      this.addPurchaseForm.wp_id=this.warehouselist[1]
    },
    numbermax(row){
      console.log(row)
      if (row.wei_number>row.pi_number-row.pi_warhouse_number){
        row.wei_number=row.pi_number-row.pi_warhouse_number
      }
    },
    //提交form表单
    async addupPurchase(){
      let that=this
      that.addloading=true
      const {data:res} = await this.$http.post('warehouseenter/add',this.addPurchaseForm)
      that.addloading=false
      if (res.code!==200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.$router.go(-1)
    },
    addgo(){
      this.$router.go(-1)
    },
  },

}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_date,.p_remark,.p_other_price{
  width: 250px;
}
.width250{
  width: 250px;
}
.date{
  width: 250px;

}
</style>
